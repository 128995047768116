import Telegram from '../image/telegram-newUI.svg';
import X from '../image/X-newUI.svg';
import Dextools from '../image/dextools-newUI.svg';
import WalletWight from '../image/wallet-wight.svg';
import Play from '../image/paly.svg';
import PaperLable from '../image/$paper-label-newUI.png';
import TokenomicsImg from '../image/tokenomics-img-newUI.jpg';
import GameBannerTheGame from '../image/banner-the-game.jpg';
import GameBannerPeople from '../image/banner-game-people.jpg';
import GameGif from '../image/gif-game.gif';

export const ImageEnum = {
  Telegram,
  X,
  Dextools,
  WalletWight,
  Play,
  PaperLable,
  TokenomicsImg,
  GameBannerTheGame,
  GameBannerPeople,
  GameGif,
} as const;
